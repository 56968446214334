.App {
  text-align: center;
  margin: 5px;
}

.App-logo {
  font-size: 100px;
  pointer-events: none;
  color: purple;
}

.Feature-logo {
  font-size: 60px;
  pointer-events: none;
  color: purple;
}

.Background-image {
  font-size: 180px;
  pointer-events: none;
  opacity: 0.2;
  filter: grayscale(1);
}

@media (prefers-reduced-motion: no-preference) {
  .Background-image {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-action {
  background-color: purple;
  border-color: purple;
  color: white;
}
